.social-buttons {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 12;

  display: flex;
  flex-direction: column;

  background-color: $texts_color;
  color: white;
  padding: 10px;

  a {
    color: white;
    cursor: pointer;

    &:hover {
      opacity: .8;
    }
  }

  a:not(:last-child) {
    padding-bottom: .5em;
  }

  i:not(:last-child) {
    margin-bottom: 10px;
  }
}