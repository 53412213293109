h1 {
  font-size: 3em;
  text-transform: uppercase;
  font-weight: 300;
  @include media-breakpoint-down(sm) {
    font-size: 2em;
  }
}

h2 {
  font-size: 1.5em;
  font-weight: 300;
}

h3 {
  font-size: 1em;
  font-weight: 300;
}