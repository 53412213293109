.triangle-map {
  z-index: 10;
  clip-path: polygon(100% 0, 0% 115%, 100% 100%);
  background-color: $nature_color;
  color: white;
  padding-left: 4rem;
  height: 180px;
  width: 180px;

  position: fixed;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;

  p {
    font-family: $regular_font;
    font-weight: 600;
  }

  &:hover {
    background-color: $secondary-color;
  }
}

@include media-breakpoint-down(xs) {
  .triangle-map {
    height: 110px;
    width: 140px;
    img {
      width: 20px;
    }
    p {
      font-size: 12px;
    }
  }
}