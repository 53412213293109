.modal-open {
  padding-right: 0px !important;
}

#downloadsModal {
  .modal-content {
    border-radius: 0;

    .modal-header {
      border: 0;

      .modal-title {
        font-size: 24px;
        font-weight: 900;
        color: $primary-color;
      }
    }

    .modal-body {
      .file-info {
        width: 90%;
      }

      .text-download-file {
        a, p {
          text-decoration: none;
          color: $secondary_color;
        }

        a {
          &:hover {
            opacity: 0.7;
          }
        }

        i {
          width: 36px;
          color: $primary-color;
        }

        p {
          margin: 0;
          word-wrap: break-word;
        }
      }
    }
  }
}
