@import "_variables";
@import "_mixins";

body {
  font-family: $regular_font;
}

.gallery-info {
  position: absolute;
  z-index: 10;
  bottom: 30px;
  right: 0;
  color: white;

  #gallery-button {
    margin-right: 40px;
  }
}

.modal-content {
  border-radius: 0;
  border: none;
}

.rich-text a {
  color: $primary_color;
}

.rich-text iframe {
  width: 100%;
  height: 400px;
  padding: 10px 0;
}

.pagination-generic-page {
  list-style-type: none;
  margin-top: 2em;
  padding-left: 0;
  margin-left: 25px;

  .page-link,
  .page-item.active .page-link {
    color: $primary_color;
    background-color: transparent;
    border: 0;
    padding: 0 1em 0 0;
    font-weight: bold;
    cursor: pointer;
    &:focus {
      box-shadow: none;
    }
  }

  .page-item.active .page-link {
    text-decoration: underline;
  }
}

@include media-breakpoint-down(xs) {
  #gallery-button {
    margin-right: 20px !important;
  }
}