#generic-header {
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  .overlay {
    @include default-overlay(.3, 0);
  }

  .generic-header-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    z-index: 1;
    height: 100%;

    color: white;
    text-align: center;

    h1 {
      text-transform: uppercase;
    }
  }
}

.generic-type,
.event-type {
  height: 50vh;
  .overlay {
    height: 50vh;
  }
  .generic-header-title {
    justify-content: center;
  }
}

.village-type {
  height: 100vh;

  .overlay {
    height: 100vh;
  }

  .generic-header-title {
    justify-content: flex-end;
    padding-bottom: 40px;

    .village-categories {
      display: flex;

      img {
        height: 40px;
        width: auto;
      }
    }

    #scroll-down {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-down(sm) {
  .generic-header-title {
    padding-top: 70px;
  }
}