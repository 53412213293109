#municipality-filter {
  .municipality {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &.active {
      border-bottom: 4px solid $primary_color;
      color: $primary_color;
    }

    p {
      margin-bottom: 8px;
      font-size: 18px;
      font-weight: 600;
      white-space: nowrap;
    }
  }

  .slick-track {
    display: flex;
  }

  i {
    position: absolute;
    top: calc(50% - 12px);
    color: $primary_color;
    cursor: pointer;

    &.prev {
      right: 0;
    }

    &.next {
      right: -40px;
    }

    &.slick-disabled {
      color: #ccc;
    }
  }
}

.gradient {
  width: 100px;
  position: absolute;
  right: 10px;
  height: 100%;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1); 
}

@include media-breakpoint-down(md) {
  .municipality-container {
    max-width: 540px;
  }
}

@include media-breakpoint-down(sm) {
  #municipality-filter {
    i {
      top: initial;
      bottom: -35px;

      &.prev {
        left: 15px;
      }

      &.next {
        left: 55px;
        right: initial;
      }
    }
  }
}
