.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin: 0;
  margin-left: 2em;

  .icon-home, a {
    color: $texts_color;
  }

  .icon-home {
    padding-bottom: 5px;
  }
}

.breadcrumb-go-back {
  color: #999;
  a {
    text-decoration: none;
    color: #999;

    &:hover {
      opacity: 0.7;
    }
  }
  .go-back {
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

@include media-breakpoint-down(xs) {
  .breadcrumb-go-back {
    flex-direction: column;
    .go-back {
      margin-bottom: .5em;
    }
    .breadcrumb {
      justify-content: center;
    }
  }
}