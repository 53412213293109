#gallery-header {
  height: 60vh;
  width: 100%;
  position: relative;

  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  .overlay {
    @include default-overlay(.3, 0);
    height: 60vh;
  }
}
