.triangle-logo {
  z-index: 110;
  clip-path: polygon(50% 0, 100% 0, 35% 100%, 0 40%, 0 0);
  height: 300px;
  width: 350px;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  padding-top: 40px;
  padding-right: 60px;

  -webkit-transition: all .4s ease-in-out;
  -o-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;

  // Triangle only when navbar is shown (#266723)
  @media (max-width: 1304px){
    display: none;
    padding-top: 20px;
  }
}

.affix {
  top: -100%;
  left: -100%;
  -webkit-transition: all 1.8s ease-in-out;
  -o-transition: all 1.8s ease-in-out;
  transition: all 1.8s ease-in-out;
}

.white-logo {
  background-color: white;
  img {
    width: 210px;
  }
}

.green-logo {
  background-color: $primary_color;
}

.fade-out {
  animation: fadeOut ease 2s;
  -webkit-animation: fadeOut ease 2s;
  -moz-animation: fadeOut ease 2s;
  -o-animation: fadeOut ease 2s;
  -ms-animation: fadeOut ease 2s;
}

@keyframes fadeOut{
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
