#footer-newsletter {
  width: 100%;
  height: calc(100vh - 272px);
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  color: $texts_color;
  text-align: center;

  .footer-newsletter-content {
    z-index: 1;
  }

  #newsletter-form {
      display: flex;
      align-items: center;

      input, button {
          height: 50px;
      }

      input {
        border: 1px solid $footer_info_background;
        border-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        background-color: white;
        color: $footer_info_background;
        font-size: 15px;
        max-width: 230px;
        padding: 15px;
        &:focus {
          outline: none;
        }
      }

      button {
          background-color: $footer_info_background;
          border: 0;
          border-radius: 0;
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
          color: white;
          font-size: 15px;
          width: 230px;

          &:hover {
            opacity: 0.7;
          }
      }
  }

  .response-success {
    color: green;
  }

  .response-error {
    color: red;
  }

  .social-networks {
    li {
        display: inline-block;
      a {
        text-decoration: none;
        color: $texts_color;

        &:hover {
          color: $primary-color;
        }
      }
    }

    span {
        font-size: 32px;
    }

    img {
        height: 20px;
    }
  }
}

#footer-links {
  background-color: $footer_submenu_background;
  .link-list {
    list-style-type: none;
    a {
      line-height: 2;
      text-decoration: none;
      color: white;
      &:hover {
          color: $nature_color;
      }
    }
  }
}

#footer-info {
  background-color: $footer_info_background;
  color: white;
  position: relative;
  a {
    color: inherit;
  }

  .copyright-section {
    background-color: $footer_info_background;
    .copyright, .copyright a {
      text-decoration: none;
      color: #666;
    }
    a:hover {
      color: $nature_color;
    }
  }

  .financing-section {
    width: 620px;
    margin-left: auto;
    margin-right: auto;
    p {
      font-size: 14px;
    }
    img {
      padding-bottom: 2rem;
    }
  }

  #scrollToTopBtn {
    position: absolute;
    right: 8em;
    transform: translateY(-50%);
    top: 50%;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

@include media-breakpoint-down(lg) {
  #scrollToTopBtn {
    right: 1em !important;
    top: 10% !important;
  }
}

@include media-breakpoint-down(md) {
  #footer-newsletter {
    height: calc(100vh - 200px);
    .footer-newsletter-email{
      max-width: 150px !important;
    }

    .subscribe-newsletter-btn {
      width: 130px !important;
    }
  }

  #footer-info .financing-section {
      width: auto;
    }

  .footer-address {
    margin-top: 20px;
  }

  .footer-email {
    word-break: break-all;
  }

  .footer-sdiram {
    padding-left: 15px !important;
  }

}

@include media-breakpoint-down(sm) {
  #footer-newsletter {
    padding-top: 0 !important;
    .footer-newsletter-subscription {
      padding-bottom: 0;
      margin-top: 0 !important;
    }
  }

  .link-list {
    flex-direction: column;
    padding-left: 60px !important;
  }

  #footer-info {
    padding-left: 60px;
  }

  .footer-newsletter-content {
    padding-left: 60px;
    padding-right: 40px;
  }
}

@media (max-width: 380px) {
  .footer-newsletter-subscription {
    display: flex;
    flex-direction: column;
  }

  #footer-newsletter .subscribe-newsletter-btn {
    width: 100% !important;
  }
}

@media(max-height: 730px) and (max-width: 576px) {
  #footer-newsletter {
    height: calc(100vh - 30px);
  }
}