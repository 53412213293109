.affix-menu {
  background-color: white;

  -webkit-transition: all 0.8s ease-in-out;
  -o-transition: all 0.8s ease-in-out;
  transition: all 0.8s ease-in-out;

  .nav-overlay {
    display: none;
  }

  .nav-item, .home-link-menu {
    .nav-link, a {
      color: $texts_color !important;
    }
  }
}

.navbar {
  position: fixed;
  width: 100%;
  z-index: 100;
  height: 75px;
  top: 0;

  .navbar-toggler {
    border-color: transparent;
    &:focus {
      outline: none;
    }
  }

  .nav-link, .home-link-menu {
    text-transform: uppercase;
    font-weight: 600;
    padding-right: 1.5em !important;
    &.active {
      color: $nature_color !important;
    }
    &:hover {
      color: $nature_color !important;
    }
    a {
      text-decoration: none !important;
      &:hover {
        color: $nature_color !important;
      }
    }
  }

  .navbar-collapse {
    z-index: 1;
  }

  .dropdown {
    #languageButton {
      background-color: initial;
      box-shadow: none;
      border: none;
      transition: none;

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      border-radius: 0;
      position: absolute;

      a {
        color: $secondary_color !important;

        &:active {
          background-color: initial;
        }
      }
    }
  }
}

.menu-default {
  .nav-link, a {
    color: white !important;
    cursor: pointer;
  }

  .nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .8;
    background-image: linear-gradient(to bottom,#000,transparent);
  }
}

.nav-favorites {
  position: relative;
  .favorites-num {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 15px;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    background-color: $secondary_color;
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
}

.menu-white {
  background-color: white;
  .nav-link, a {
    color: $texts_color !important;
  }

  #navbarTogglerMenu {
    padding-right: 20px;
  }

  .navbar-brand {
    padding-left: 10px;
  }
}

#searchModal {
    overflow: hidden;
    padding-right: 0 !important;
    .modal-dialog {
      max-width: 100%;
      height: 100%;
      margin: 0;
    }

    .modal-content {
        height: 100%;
        background-color: #ebebeb;
        .modal-header {
            border-bottom: 0;
        }

        .modal-body {
          flex-direction: column;
        }
    }

    form {
        width: 500px;
    }

    .form-group {
        margin: 0;
        margin-top: 2em;
    }

    .form-control {
        padding: 20px 24px 24px 50px;
        border: 0 !important;
        background-color: white !important;
        color: $texts_color !important;
        font-size: 24px;
        border-radius: 0;
    }

    .form-group button {
        position: relative;
        top: 42px;
        left: 10px;
        border: 0;
        background-color: transparent;
        color: $texts_color;

        i {
            font-size: 24px;
        }
    }

    input {
        height: 60px;
        box-sizing: border-box;
    }
}

// Dropdown menu now shows until 1304px (#26672)
@media (max-width: 1304px) {
  .nav-overlay{
    display: none;
  }

  .navbar, #navbarTogglerMenu {
    background-color: white;
  }

  #navbarTogglerMenu {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: -6px;
    padding-right: 0 !important;
    padding-bottom: 10px;
    .navbar-nav {
      height: 100vh;
    }
  }

  .home-link-menu {
    padding-top: 80px !important;
    padding-left: 1.5em !important;
  }

  .nav-link {
    padding-left: 1.5em !important;
  }

  .nav-icons {
    padding-bottom: 60px !important;
  }

  .nav-item, .home-link-menu {
    text-align: center;
    padding-bottom: 20px;
    padding-top: 20px;
    .nav-link, a {
      color: $texts_color !important;
    }
  }

  .nav-icons {
    justify-content: center;
  }

  .favorites-num {
    top: 18px !important;
    right: 20px !important;
  }

  .form-group button i,
  #searchModal .form-control {
    font-size: 20px !important;
  }
}

@include media-breakpoint-down(sm) {
    #searchModal form {
      width: 100%;
    }
}


// Navbar only shows from 1305px (#26672)
@media (min-width: 1305px){
    .navbar-expand-custom {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-custom .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-custom .dropdown-menu {
        border-radius: 0;
        position: absolute;
    }
    .navbar-expand-custom .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-custom > .container {
        flex-wrap: nowrap;
    }
    .navbar-expand-custom .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-custom .navbar-toggler {
        display: none;
    }
    .navbar-expand-custom .custom-response {
      display: flex;
    }
    .navbar-expand-custom .custom-hide {
      display: none;
    }

}